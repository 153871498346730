import $ from 'jquery';

$(document).ready(function () {
    var accordion = document.getElementsByClassName('w3-accordion')[0];
    var acc = document.getElementsByClassName("w3-button");
    var i;

    
    if(accordion) {
        acc[0].classList.add("active");
        var panel = acc[0].nextElementSibling;
        panel.style.maxHeight = panel.scrollHeight + "px";
        for (i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function () {
                this.classList.toggle("active");
                var panel = this.nextElementSibling;
                if (panel.style.maxHeight) {
                    panel.style.maxHeight = null;
                } else {
                    panel.style.maxHeight = panel.scrollHeight + "px";
                }
            });
        }
    }
})