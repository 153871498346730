import $ from 'jquery';
import WOW from 'wow.js';

$(document).ready(function() {
	
    new WOW().init()

    $('.count').each(function (index, el) {
		let launched = false;
		$(window).scroll(function() {
			if (launched) return;
			const top = $(window).scrollTop() + $(window).outerHeight(true);
			const countBottom = $(el).offset().top + $(el).outerHeight(true);
			if (top >= countBottom) {
				launched = true;
				$(el).prop('Counter', 0).animate({
					Counter: $(el).text()
				}, {
					duration: 3000,
					easing: 'swing',
					step: function (now) {
						$(el).text(Math.ceil(now));
					}
				});
			}
		});
	});
	
	$('.telechargement .sf-field-post-meta-type_produit h4').on('click', function() {
		$('.telechargement .sf-field-post-meta-type_produit ul').slideToggle( "slow", function() {
			// Animation complete.
		});
	});

	$('.ginput_recaptcha').each(function(i)
	{
		var thisID = $(this).attr('id');
		$(this).attr('id', thisID+'_'+i);
	});

});