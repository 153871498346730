import $ from 'jquery';
import 'slick-carousel';

$(document).ready(function () {

	$('.banner_slider').slick({
		arrows: true,
		dots: false,
		infinite: true,
		prevArrow: $('.prev'),
		nextArrow: $('.next')
	});

	$('.members_slider').slick({
		arrows: false,
		dots: false,
		infinite: true,
		speed: 300,
		slidesToShow: 6,
		slidesToScroll: 6,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			}
		]
	});
	
	$('.soutiens_slider').slick({
			arrows: false,
			dots: false,
			infinite: true,
			speed: 300,
			slidesToShow: 6,
			slidesToScroll: 6,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 4
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				}
			]
		});

	$('.marches_slider').slick({
		arrows: false,
		dots: false,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 4000
	});

	$('.slider_lab_temoignages').slick({
		arrows: false,
		dots: true,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 8000
	});

	$('.carriere_temoignages_slider').slick({
		arrows: false,
		dots: true,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: false,
		autoplaySpeed: 8000
	});

	$('.sur_mesure_slider').slick({
		arrows: false,
		dots: true,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 8000
	});

	$('.soutiens_slider').slick({
		arrows: false,
		dots: false,
		infinite: true,
		speed: 300,
		slidesToShow: 4,
		slidesToScroll: 1,
		autoplay: false,
		autoplaySpeed: 8000,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			}
		]
	});

	$('.produits_type1_niv_2_slider').slick({
		arrows: true,
		prevArrow: $('.prev'),
		nextArrow: $('.next'),
		dots: false,
		infinite: false,
		speed: 300,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			}
		]
	});

	$("div[class^=produits_type1_niv_3_slider_]").each(function () {
		let id = $(this).attr('class').split("_").at(-1);
		$(".produits_type1_niv_3_slider_" + id).slick({
			arrows: true,
			prevArrow: $('.produits_type1_niv_3_slider_prev_' + id),
			nextArrow: $('.produits_type1_niv_3_slider_next_' + id),
			dots: false,
			infinite: false,
			speed: 300,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: false
		});
	})

	$('.produits_type2_niv_2_galerie_slider').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		fade: true,
		asNavFor: '.produits_type2_niv_2_galerie_slider_nav'
	});
	$('.produits_type2_niv_2_galerie_slider_nav').slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		asNavFor: '.produits_type2_niv_2_galerie_slider',
		dots: false,
		centerMode: true,
		focusOnSelect: true
	});

	$('.produits_type2_niv_2_accessoires_slider').slick({
		arrows: false,
		dots: true,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: false,
		autoplaySpeed: 8000
	});

	$('.axem_lab_videos_slider').slick({
		arrows: true,
		dots: false,
		infinite: true,
		centerMode: true,
		speed: 300,
		slidesToShow: 3,
		slidesToScroll: 1,
		autoplay: false,
		autoplaySpeed: 8000,
		prevArrow: $('.axem_lab_videos_slider_prev'),
		nextArrow: $('.axem_lab_videos_slider_next'),
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});

});