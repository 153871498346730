import $ from 'jquery';
window.jQuery = $;
import './back-to-top.js';
import './header.js';
import './home.js';
import './slick.js';
import 'popper.js';
import 'bootstrap';
import './w3-accordion';
import 'wow.js';
import './filters';