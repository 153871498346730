import $ from 'jquery';

$(document).ready(function () {

    let filter_1_industry = document.getElementById('filter_1_industry');
    let filter_1_frequency = document.getElementById('filter_1_frequency');

    if (filter_1_industry && filter_1_frequency) {
        let products = document.getElementsByClassName('produits_type1_niv_3');
        products = Array.from(products)

        filter_1_industry.onchange = () => {
            filter_1(products, filter_1_frequency.value, filter_1_industry.value)
        }

        filter_1_frequency.onchange = () => {
            filter_1(products, filter_1_frequency.value, filter_1_industry.value)
        }
    }

    function filter_1(products, frequency_value, industry_value) {
        products.forEach(product => product.classList.remove("d-none"))
        let match_products = products.filter((product) =>
            (!industry_value || JSON.parse(product.getAttribute('data-industries')).includes(industry_value)) &&
            (!frequency_value || JSON.parse(product.getAttribute('data-frequencies')).includes(frequency_value))
        )
        let unmatch_products = products.filter(function (product) {
            return !match_products.includes(product);
        })
        unmatch_products.forEach(product => product.classList.add("d-none"))
    }

    let filters_2 = document.getElementById('filters_2');

    if (filters_2) {
        let products = document.getElementsByClassName('produit_type2_niv_1');
        products = Array.from(products)
        let filters = document.querySelectorAll('*[id^="filter_2"]');
        let filter_2_industry = document.getElementById('filter_2_industry');
        let filter_2_frequency = document.getElementById('filter_2_frequency');
        let filter_2_screen = document.getElementById('filter_2_screen');
        let filter_2_indice = document.getElementById('filter_2_indice');
        let filter_2_os = document.getElementById('filter_2_os');
        for (let filter of filters) {
            filter.onchange = () => {
                filter_2(products, filter_2_frequency.value, filter_2_industry.value, filter_2_indice.value, filter_2_os.value, filter_2_screen.value)
            }
        }
    }

    function filter_2(products, frequency_value, industry_value, indice_value, os_value, screen_value) {
        products.forEach(product => product.classList.remove("d-none"))
        let match_products = products.filter((product) =>
            (!industry_value || JSON.parse(product.getAttribute('data-industries')).includes(industry_value)) &&
            (!frequency_value || JSON.parse(product.getAttribute('data-frequencies')).includes(frequency_value)) &&
            (!indice_value || product.getAttribute('data-indice') == indice_value) &&
            (!os_value || product.getAttribute('data-os') == os_value) &&
            (!screen_value || product.getAttribute('data-screen') == screen_value)
        )

        let unmatch_products = products.filter(function (product) {
            return !match_products.includes(product);
        })
        unmatch_products.forEach(product => product.classList.add("d-none"))
    }
});